import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Это страница'
const DEFAULT_NAME = 'ITLE - Работа'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Авторизация',
      layout: 'free',
      login: false,
    }
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Главная страница',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/vacancy',
    name: 'vacancy',
    component: () => import('../views/vacancy/index.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Вакансии',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/vacancy/create',
    name: 'vacancy-create',
    component: () => import('../views/vacancy/create.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Создание вакансии',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/vacancy/:id',
    name: 'vacancy-update',
    component: () => import('../views/vacancy/update.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Редактирование вакансии',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/categories/index.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Категории',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/categories/create',
    name: 'categories-create',
    component: () => import('../views/categories/create.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Создание категории',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/categories/:id',
    name: 'categories-update',
    component: () => import('../views/categories/update.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Редактирование категории',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/restaurants',
    name: 'restaurants',
    component: () => import('../views/restaurants/index.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Рестораны',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/restaurants/create',
    name: 'restaurants-create',
    component: () => import('../views/restaurants/create.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Создание ресторана',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/restaurants/:id',
    name: 'restaurants-update',
    component: () => import('../views/restaurants/update.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Редактирование ресторана',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('../views/icons/index.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Иконки',
      layout: 'default',
      login: true,
    }
  },
  {
    path: '/icons/create',
    name: 'icons-create',
    component: () => import('../views/icons/create.vue'),
    meta: {
      title: DEFAULT_NAME + ' - Добавление иконки',
      layout: 'default',
      login: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
  if (to.matched.some(record => record.meta.login)) {
    if (store.getters.isLogin) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
});

export default router
