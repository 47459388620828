import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/auth/login'
import categories from '@/store/categories/categories'
import restaurants from '@/store/restaurants/restaurants'
import vacancy from '@/store/vacancy/vacancy'
import schedule from '@/store/schedule/schedule'
import employment from '@/store/employment/employment';
import icons from '@/store/icons/icons'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
  },
  getters: {
    isLogin: state => !!state.token,
  },
  actions: {
    logout(ctx) {
      ctx.commit('destroyToken')
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  },
  modules: {
    login, categories, restaurants, vacancy, schedule, employment, icons
  }
})