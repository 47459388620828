import Vue from 'vue'
import axios from 'axios'
import store from '../../store/index'

Vue.config.productionTip = true

const baseURL = Vue.config.productionTip ? 'https://api.itle-hr.ru/api' : 'http://itle.job/api'

const http = axios.create({
    baseURL
})

http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    },

    e => {
        return Promise.reject(e)
    }
)

http.interceptors.response.use((response)  => {
    return response;
}, function (error) {
    switch (error.response.status) {
        case 401: {
            store.dispatch('logout')
            break;
        }
    }
    return Promise.reject(error);
});

export default http
