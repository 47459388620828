import http from "@/config/axios/axios";
export default {
    state: {},
    getters: {},
    actions: {
        login(ctx, data) {
            http.post('/login', data).then(r => {
                const token = r.data.data.token
                localStorage.setItem('token', token)
                ctx.commit('setToken', token)
                window.location.href = '/'
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    alert(error.response.data.message)
                }
            });
        }
    },
    mutations: {}
}
