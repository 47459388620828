import http from "@/config/axios/axios";

export default {
    state: {
        employment: []
    },
    getters: {
        _getEmployment: state => state.employment,
    },
    actions: {
        getEmploymentInfo(ctx, data) {
            http.get('/admin/employments').then(r => {
                ctx.commit('setEmploymentInfo', r.data.data.employments)
            });
        },
    },
    mutations: {
        setEmploymentInfo(state, data) {
            state.employment = data;
        }
    }
}
