import http from "@/config/axios/axios";
import router from "@/router";

export default {
    state: {
        category: [],
        categories: []
    },
    getters: {
        _getCategory: state => state.category,
        _getCategories: state => state.categories,
    },
    actions: {
        getCategory(ctx, data) {
            http.get('/admin/categories/' + data.id).then(r => {
                ctx.commit('setCategory', r.data.data.category)
            });
        },
        getCategoriesInfo(ctx, data) {
            http.get('/admin/categories').then(r => {
                ctx.commit('setCategoriesInfo', r.data.data.categories)
            });
        },
        updateCategory(ctx, data) {
            http.put('/admin/categories/' + data.id, data).then(r => {
                alert(r.data.message)
            })
        },
        createCategory(ctx, data) {
            http.post('/admin/categories', data).then(r => {
                router.push('/categories')
                alert(r.data.message)
            }).catch(error => {
                if (error.response && error.response.status === 422) {
                    alert(error.response.data.data.errors)
                }
            });
        },
    },
    mutations: {
        setCategory(state, data) {
            state.category = data;
        },
        setCategoriesInfo(state, data) {
            state.categories = data;
        }
    }
}
