import http from "@/config/axios/axios";

export default {
    state: {
        schedule: []
    },
    getters: {
        _getSchedule: state => state.schedule,
    },
    actions: {
        getScheduleInfo(ctx, data) {
            http.get('/admin/schedule').then(r => {
                ctx.commit('setScheduleInfo', r.data.data.schedule)
            });
        },
    },
    mutations: {
        setScheduleInfo(state, data) {
            state.schedule = data;
        }
    }
}
