import http from "@/config/axios/axios";
import router from "@/router";

export default {
    state: {
        vacancy: [],
        showVacancy: [],
    },
    getters: {
        _getVacancy: state => state.vacancy,
        _getShowVacancy: state => state.showVacancy,
    },
    actions: {
        getVacancy(ctx, data) {
            http.get('/admin/vacancy/' + data.id).then(r => {
                ctx.commit('setVacancy', r.data.data.vacancy)
            });
        },
        getVacancyInfo(ctx, data) {
            http.get('/admin/vacancy').then(r => {
                ctx.commit('setVacancyInfo', r.data.data.vacancy)
            });
        },
        createVacancy(ctx, data) {
            http.post('/admin/vacancy', data).then(r => {
                router.push('/vacancy')
                alert(r.data.message)
            }).catch(error => {
                if (error.response && error.response.status === 422) {
                    alert(error.response.data.data.errors)
                }
            });
        },
        updateVacancy(ctx, data) {
            http.put('/admin/vacancy/' + data.id, data).then(r => {
                alert(r.data.message)
            })
        },
        destroyVacancy(ctx, data) {
            http.delete('/admin/vacancy/' + data).then(r => {
                ctx.commit('setVacancyInfo', r.data.data.vacancy)
                alert(r.data.message)
            })
        },
    },
    mutations: {
        setVacancy(state, data) {
            state.showVacancy = data;
        },
        setVacancyInfo(state, data) {
            state.vacancy = data;
        }
    }
}
