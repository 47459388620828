<template>
  <b-navbar type="dark" variant="dark">
    <b-navbar-nav>
      <b-link to="/" class="nav-link">Главная</b-link>
      <b-link to="/vacancy" class="nav-link">Вакансии</b-link>
      <b-link to="/icons" class="nav-link">Иконки</b-link>
      <b-link to="/categories" class="nav-link">Категории</b-link>
      <b-link to="/restaurants" class="nav-link">Рестораны</b-link>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {};
</script>