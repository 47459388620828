<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import Default from '@/components/layouts/Default.vue';
import Free from '@/components/layouts/Free.vue';

export default {
  name: 'App',
  computed: {
    layout: function () {
      return (this.$route.meta.layout)
    },
  },
  methods: {},
  components: {
    Default, Free
  }
}
</script>
