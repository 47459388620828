import http from "@/config/axios/axios";
import router from "@/router";

export default {
    state: {
        icons: [],
        showIcon: [],
    },
    getters: {
        _getIcons: state => state.icons,
        _getShowIcons: state => state.showIcon,
    },
    actions: {
        getIcons(ctx, data) {
            http.get('/admin/icons/' + data.id).then(r => {
                ctx.commit('setIcon', r.data.data.icon)
            });
        },
        getIconsInfo(ctx) {
            http.get('/admin/icons').then(r => {
                ctx.commit('setIconsInfo', r.data.data.icons)
            });
        },
        updateVacancy(ctx, data) {
            http.put('/admin/vacancy/' + data.id, data).then(r => {
                alert(r.data.message)
            })
        },
        createIcon(ctx, data) {
            const formData = new FormData();
            formData.append('file', data.file);
            http.post('/admin/icons', formData).then(r => {
                router.push('/icons')
                alert(r.data.message)
            }).catch(error => {
                if (error.response && error.response.status === 422) {
                    alert(error.response.data.data.errors)
                }
            });
        },
    },
    mutations: {
        setIcon(state, data) {
            state.showIcon = data;
        },
        setIconsInfo(state, data) {
            state.icons = data;
        }
    }
}
