import http from "@/config/axios/axios";

export default {
    state: {
        restaurants: [],
        restaurant: [],
    },
    getters: {
        _getRestaurant: state => state.restaurant,
        _getRestaurants: state => state.restaurants,
    },
    actions: {
        getRestaurant(ctx, data) {
            http.get('/admin/restaurants/' + data.id).then(r => {
                ctx.commit('setRestaurant', r.data.data.restaurant)
            });
        },
        getRestaurantsInfo(ctx, data) {
            http.get('/admin/restaurants').then(r => {
                ctx.commit('setRestaurantsInfo', r.data.data.restaurants)
            });
        },
        updateRestaurant(ctx, data) {
            http.put('/admin/restaurants/' + data.id, data).then(r => {
                alert(r.data.message)
            })
        },
        createRestaurants(ctx, data) {
            http.post('/admin/restaurants', data).then(r => {
                alert(r.data.message)
            })
        },
    },
    mutations: {
        setRestaurant(state, data) {
            state.restaurant = data;
        },
        setRestaurantsInfo(state, data) {
            state.restaurants = data;
        }
    }
}
